import React, { useState, useEffect } from "react";
import { Flex, Text } from "@chakra-ui/react";

type Props = {
  createdAt: string;
};

const TextCounter = ({ createdAt }: Props) => {
  const [counter, setCounter] = useState(600);

  const dateNow: any = new Date();
  const diff = Math.floor((dateNow - Date.parse(createdAt)) / 1000);

  const timeLeft = () => {
    return [Math.floor(counter / 60), Math.floor(counter % 60)];
  };

  const [mins, secs] = timeLeft();
  const formater = (num: number) => (num < 10 ? `0${num}` : num);

  useEffect(() => {
    if (diff) {
      if (counter - diff <= 0) {
        setCounter(0);
      } else {
        setCounter(counter - diff);
      }
    }
  }, [createdAt]);

  useEffect(() => {
    const time = setTimeout(() => {
      if (counter > 0) setCounter((prev) => prev - 1);
    }, 1000);
    return () => clearTimeout(time);
  }, [counter]);

  return (
    <Flex w="100%" direction="column">
      <Text fontSize="15px" fontWeight="400" color="#444444">
        Se você já efetuou o pagamento, aguarde, o tempo médio de resposta do
        PIX é de:
      </Text>
      <Text fontSize="20px" fontWeight="600" color="#015CFF">
        {`${formater(mins)}:${formater(secs)}`}
      </Text>
      <Text fontSize="15px" fontWeight="400" color="#444444">
        Você será notificado via SMS.
      </Text>
    </Flex>
  );
};

export default TextCounter;
