import React from "react";
import ReactDOM from "react-dom/client";
// import "./index.css";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import HttpsRedirect from "react-https-redirect";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <GoogleReCaptchaProvider
        reCaptchaKey="6LfOIs4iAAAAAGlPJtUJYiCf1CbrKgd05jo82oPR"
        language="[pt-BR]"
      >
        <HttpsRedirect>
          <App />
        </HttpsRedirect>
      </GoogleReCaptchaProvider>
    </ChakraProvider>
  </React.StrictMode>
);
