import React from "react";
import { Heading } from "@chakra-ui/react";

type Props = {
  text: string;
};

const MainText = ({ text }: Props) => {
  return (
    <Heading w="100%" fontSize="25px">
      {text}
    </Heading>
  );
};

export default MainText;
