import React from "react";
import { Flex } from "@chakra-ui/react";

type Props = {
  opacity?: string;
  children: React.ReactNode;
};

const Wrapper = ({ opacity, children }: Props) => {
  return (
    <Flex
      justify="top"
      opacity={opacity}
      minH="100vh"
      bg="#F3F7FF"
      align="center"
      direction="column"
    >
      <Flex
        bg="#F3F7FF"
        direction="column"
        justify="center"
        align="center"
        px="20px"
        py="20px"
        gap="30px"
        w="100%"
        maxW="400px"
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default Wrapper;
