import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";

type TProps = {
  isOpen: boolean;
  onClose: any;
};
const TermosDeUsoModal = ({ isOpen, onClose }: TProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent h="80vh">
        <ModalHeader>Termos de uso</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto" fontSize="10" gap="13px">
          <Text>Termos de uso e Políticas de Privacidade</Text>
          <Text>Termos de uso</Text>
          <Text>
            Estes Termos de Uso (“Termos de Uso”) estabelecem os termos e
            condições de acesso e uso do serviço de assinatura digital de
            contratos e/ou outros documentos eletrônicos oferecido por SIGAPAY
            GESTÃO DE PAGAMENTOS LTDA., sociedade empresária limitada inscrita
            no CNPJ/MF sob o nº ******, com sede na *******i, São Paulo – SP,
            CEP ******* e inscrita no NIRE sob nº: ********, neste ato
            representada na forma de seu estatuto social.
          </Text>
          <Text>1. OBJETO</Text>
          <Text>
            1.1 Estes Termos de Uso regulam o acesso e uso do Serviço pelo
            correspondente usuário (“Usuário”) quando o aplicativo de assinatura
            digital (“Aplicativo”) e/ou o website (“Website”) da SigaPay e/ou
            outros softwares, serviços, plataformas, ferramentas, conteúdos e/ou
            recursos correlatos são instalados, acessados e/ou utilizados.
          </Text>
          <Text>
            1.2 O USUÁRIO MANIFESTARÁ ELETRONICAMENTE SUA ACEITAÇÃO ÀS CONDIÇÕES
            DESTES TERMOS DE USO E DA CORRESPONDENTE POLÍTICA DE PRIVACIDADE E
            SEGURANÇA DE DADOS AO SELECIONAR A OPÇÃO “LI E CONCORDO COM OS
            TERMOS DE USO DESSES SERVIÇOS” DISPONÍVEL NAS PÁGINAS DE CADASTRO DO
            APLICATIVO (“ACEITE ELETRÔNICO”).
          </Text>
          <Text>
            1.3 A aposição do Aceite Eletrônico pelo Usuário implica em sua
            aceitação expressa, automática, plena, sem reservas ou ressalvas, de
            todas as disposições destes Termos de Uso e da Política de
            Privacidade e Segurança de Dados aplicável ao Serviço, conforme
            eventualmente alterados.
          </Text>
          <Text>
            1.4 Os Termos de Uso e a correspondente Política de Privacidade e
            Segurança de Dados aplicável ao Serviço estarão disponíveis nos
            canais eletrônicos de acesso e uso do Aplicativo, inclusive via
            celular e Website.
          </Text>
          <Text>
            1.5 Estes Termos de Uso, além de regularem contratualmente a relação
            entre as partes, devem ser entendidos também como licença de uso dos
            softwares vinculados ao Serviço, os quais são regidos pelo direito
            autoral e por demais leis aplicáveis. Para a utilização de qualquer
            forma do Serviço, é obrigatório aceitar estes Termos de Uso e a
            respectiva Política de Privacidade e Segurança de Dados. O acesso ou
            uso do Serviço sem esta aceitação ou mediante infração constitui não
            apenas violação destes Termos de Uso, como também violação do
            direito autoral relacionado ao Serviço.
          </Text>
          <Text>
            1.6 A SigaPay reserva-se o direito de a seu exclusivo critério e,
            sem aviso prévio, modificar os Termos de Uso e a Política de
            Privacidade e Segurança de Dados, incluindo novas condições e/ou
            restrições, devendo comunicar isto ao Usuário através do Aplicativo
            e/ou do Website. A continuidade do acesso e/ou uso do Serviço pelo
            Usuário implicará em automática aceitação de eventuais atualizações
            destes.
          </Text>
          <Text>
            1.7 A versão válida e eficaz destes Termos de Uso é aquela publicada
            atualmente pelo Aplicativo e/ou pelo Website, respeitados direitos
            adquiridos, atos jurídicos perfeitos e coisas julgadas.
          </Text>
          <Text>
            1.8 O Usuário deverá sempre ler atentamente estes Termos de Uso e a
            Política de Privacidade e Segurança de Dados e não poderá escusar-se
            deles alegando ignorância sobre ao seu teor, inclusive quanto a
            eventuais modificações.
          </Text>
          <Text>
            1.9 Certos serviços e conteúdos oferecidos com relação ao Serviço
            podem ser objeto de termos de uso específicos. Neste caso, os termos
            de uso específico podem substituir, complementar ou modificar estes
            Termos de Uso. No caso de conflito entre estes Termos de Uso e
            termos de uso específicos aplicáveis à prestação do Serviço, as
            condições especiais deverão prevalecer sobre as gerais. As condições
            especiais também deverão sempre ser lidas pelo Usuário. Outros
            avisos e comunicados poderão ser realizados ao longo da prestação do
            Serviço, os quais serão considerados condições especiais e
            prevalecerão sobre estes Termos de Uso.
          </Text>
          <Text>2. DISPONIBILIDADE DOS SERVIÇOS</Text>
          <Text>
            2.1 A SigaPay envidará seus melhores esforços para assegurar e
            desenvolver a qualidade dos Serviços.
          </Text>
          <Text>
            2.2 A SigaPay se compromete a empregar seus melhores esforços,
            dentro de razoabilidade técnica e econômica, para manter os Serviços
            disponíveis aos Usuários ininterruptamente e realizar paradas
            programadas para manutenção fora do horário comercial. Entretanto,
            poderão existir situações fora do controle razoável da SigaPay que
            venham a determinar de forma imprevista a suspensão da
            disponibilidade dos Serviços, tais como casos fortuitos ou de força
            maior.
          </Text>
          <Text>
            2.3 Para uso do Serviço, os Usuários deverão providenciar, por sua
            própria iniciativa e custo, acesso à Internet e telefones celulares
            compatíveis.
          </Text>
          <Text>3. RESPONSABILIDADES DO USUÁRIO PELO USO DOS SERVIÇOS</Text>
          <Text>
            3.1 Ao se cadastrar, o Usuário procederá à abertura de uma conta
            para sua identificação perante a SigaPay (“Conta”). O Usuário deverá
            ser maior de idade e/ou emancipado, sendo que a responsabilidade por
            qualquer falsidade, inexatidão ou omissão de dados na abertura e
            manutenção da Conta é exclusivamente do Usuário.
          </Text>
          <Text>3.2 O Usuário compreende e reconhece que:</Text>
          <Text>
            3.2.1 Possui capacidade jurídica para celebrar estes Termos de Uso e
            utilizar o Serviço, sendo que menores de idade deverão ser
            assistidos ou representados por seus pais, tutores ou curadores, na
            forma da lei, os quais serão considerados responsáveis por todos os
            atos praticados pelos menores;
          </Text>
          <Text>
            3.2.2 Toda informação oferecida pelo Usuário para acessar e usar o
            Serviço deverá ser verdadeira;
          </Text>
          <Text>
            3.2.3 O Usuário garante a autenticidade de todos os dados que
            informar através do preenchimento dos formulários disponibilizados e
            é de sua exclusiva responsabilidade manter qualquer informação
            fornecida à SigaPay permanentemente atualizada de forma a sempre
            refletir os dados reais do Usuário;
          </Text>
          <Text>
            3.2.4 O fornecimento de declarações falsas ou inexatas constitui
            violação destes Termos de Uso, bem como revogação da licença de
            utilização do Serviço, além de poder configurar em crimes de
            identidade falsa (artigo 307 do Código Penal) ou falsidade
            ideológica (artigo 299 do Código Penal);
          </Text>
          <Text>
            3.2.5 O Usuário será sempre o único e exclusivo responsável pela sua
            conduta e por danos por ela causados no âmbito da prestação do
            Serviço, quanto a ele e quanto a terceiros, sendo também o único
            responsável pelo uso de seu celular para acesso à Conta e ao
            Serviço, sendo esta ação pessoal e intransferível, devendo o Usuário
            indenizar aqueles que sofrerem danos e/ou prejuízos pela utilização
            incorreta e/ou fraudulenta dos seus aparelhos;
          </Text>
          <Text>
            3.2.6 A SigaPay poderá averiguar as informações prestadas pelo
            Usuário e, caso detecte qualquer irregularidade, poderá suspender o
            seu acesso até que tal irregularidade seja corrigida e/ou expedir
            aviso de suspensão de acesso;
          </Text>
          <Text>
            3.2.7 Ao utilizar o Serviço, tanto o Usuário quanto os terceiros
            envolvidos na assinatura digital de contratos e/ou outros documentos
            (“Signatários”) enviarão à SigaPay informações (incluindo, sem
            limitação, dados e fotos pessoais), autorizando a SigaPay a coletar
            e usar as informações fornecidas para a administração de sua base de
            dados, bem como para registrá-las e armazená-las em suporte físico
            ou intangível, incluindo, mas não se limitando a arquivamento
            magnético, eletrônico, digital, e/ou qualquer outro meio possível de
            armazenamento de informação;
          </Text>
          <Text>
            3.2.8 Todos os direitos, evidências, indícios, atestados, registros,
            provas técnicas e demais resultados decorrentes da utilização dos
            Serviços são estendidos aos Signatários dos contratos e/ou outros
            documentos;
          </Text>
          <Text>
            3.2.9 As comunicações e transações eletrônicas entre Usuários,
            Signatários e SigaPay poderão se constituir em evidências probantes
            e materializadas dos atos perpetrados com utilização dos Serviços;
          </Text>
          <Text>
            3.2.10 Os presentes Termos de Uso vinculam Usuários a Signatários;
          </Text>
          <Text>
            3.2.11 Leu, entendeu e está de pleno acordo com todas as condições
            destes Termo de Uso.
          </Text>
          <Text>
            3.3 Usuário poderá utilizar os Serviços enquanto houver créditos na
            sua Conta.
          </Text>
          <Text>
            3.4 O Usuário se compromete a utilizar o Serviço de boa-fé e em
            conformidade com todos os preceitos legais, regulamentares e
            contratuais porventura aplicáveis, bem como com a ordem pública, a
            moral e os bons costumes e as normas de conduta geralmente aceitas.
            Qualquer violação a este item poderá sujeitar o Usuário à exclusão
            da lista de usuários do Serviço, sem prejuízo de outras sanções
            administrativas, civis e/ou penais eventualmente aplicáveis.
          </Text>
          <Text>4. SEGURANÇA E PRIVACIDADE DE DADOS E DOCUMENTOS</Text>
          <Text>
            4.1 A SigaPay se obriga a, por meio de mecanismos físicos e
            tecnológicos, não divulgar os dados cadastrais dos Usuários e dos
            Signatários. Tais mecanismos atendem padrões razoáveis de cuidado,
            considerando-se as possibilidades técnicas e economicamente
            razoáveis da tecnologia aplicável à Internet, como o uso de firewall
            para proteção contra acessos indevidos. No entanto, a SigaPay
            recomenda fortemente que os Usuários adotem medidas de segurança em
            seu celular, tais como a instalação de programa antivírus e de
            firewall contra invasões.
          </Text>
          <Text>
            4.2 Para a prestação do Serviço a SigaPay adota níveis de segurança
            dentro dos requisitos legais para a proteção dos dados pessoais.
            Entretanto, é importante que o usuário tenha ciência de que pela
            própria natureza e características técnicas da Internet, essa
            proteção não é infalível e encontra-se sujeita à violação pelas mais
            diversas práticas maliciosas. Os tratamentos de dados pessoais
            conduzidos pela SigaPay são regidos por sua política de privacidade.
            Os tratamentos de dados pessoais conduzidos pela SigaPay para a
            prestação do Serviço são regidos por sua Política de Privacidade e
            Segurança de Dados.
          </Text>
          <Text>
            5. LIMITAÇÕES E EXCLUSÃO DE GARANTIAS E DE RESPONSABILIDADES
          </Text>
          <Text>
            5.1 Por razões técnicas e de operação, não é possível garantir a
            disponibilidade e continuidade das plataformas de prestação do
            Serviço, bem como evitar sua falibilidade.
          </Text>
          <Text>
            5.2 A SigaPay não será responsável, em nenhuma hipótese, por danos
            ocorridos aos Usuários e/os Signatários decorrentes de qualquer
            causa fora do controle razoável de SigaPay, tais como, mas não se
            limitando a:
          </Text>
          <Text>
            5.2.1 Divergência de dados dos Usuários e/ou Signatários que
            impossibilite o uso dos Serviços;
          </Text>
          <Text>
            5.2.2 Utilização de celulares que impeçam a realização de
            assinaturas digitais mediante uso dos Serviços;
          </Text>
          <Text>
            5.2.3 Falha de comunicação com a Internet por parte dos Usuários
            e/ou Signatários;
          </Text>
          <Text>
            5.2.4 Problemas no processamento de pagamento dos Serviços;
          </Text>
          <Text>5.2.5 Casos fortuitos ou de força maior.</Text>
          <Text>
            5.3 A SigaPay não se responsabiliza por quaisquer custos, prejuízos
            ou danos que sejam causados aos Usuários, Signatários ou quaisquer
            terceiros em decorrência da utilização dos Serviços e/ou pelo
            conteúdo e finalidade dos contratos e/ou outros documentos
            eletrônicos que sejam assinados através do Serviço e, por esta
            razão, não controla, verifica nem atesta o teor ou a legalidade de
            tal conteúdo, que é de responsabilidade total e exclusiva do Usuário
            e dos Signatários.
          </Text>
          <Text>
            5.4 A SigaPay não se responsabiliza por quaisquer custos, prejuízos
            ou danos de qualquer natureza que possam decorrer da incorreta
            identidade do Usuário, nos casos de utilização indevida e/ou
            fraudulenta dos celulares com os quais será feita a assinatura de
            contratos e/ou outros documentos.
          </Text>
          <Text>
            5.5 A SigaPay não tem obrigação de controlar e não controla,
            autoriza nem se responsabiliza caso o Usuário venha a utilizar os
            Serviços: (i) para quaisquer fins ou meios ilegais, difamatórios,
            discriminatórios, ofensivos, obscenos, injuriosos, caluniosos,
            violentos, ou de assédio, ameaça ou uso de falsa identidade; (ii)
            cujo conteúdo seja ilegal ou em violação da moral, bons costumes ou
            da ordem pública.
          </Text>
          <Text>
            5.6 A SigaPay igualmente não se responsabiliza pelos resultados
            pretendidos ou verificados com a utilização dos Serviços.
          </Text>
          <Text>
            5.7 A SigaPay não realizará a guarda nem o arquivamento de qualquer
            contrato e/ou outro documento inserido (uploaded) para fins de
            assinatura por meio do Aplicativo. Por esta razão, o Usuário e/ou os
            Signatários dos contratos e/ou outros documentos deverão tomar todas
            as medidas necessárias para a guarda e arquivamento dos contratos
            e/ou outros documentos assinados através do Aplicativo.
          </Text>
          <Text>
            5.8 O Usuário não poderá de nenhuma forma empregar mecanismos
            técnicos que de qualquer forma subvertam a regular utilização do
            Serviço, sem a prévia consulta aos gestores da SigaPay. Esses
            mecanismos incluem a utilização de robôs (robots, ou bots), spiders,
            scripts ou qualquer outra forma de acesso automatizado que de alguma
            forma sirva para desvirtuar suas finalidades e propósitos. O emprego
            desses recursos sem autorização prévia implica violação destes
            Termos de Uso uso e sujeita a parte infringente ao pagamento de
            danos emergentes e lucros cessantes, bem como de multa punitiva que
            se aplicará mesmo que não tenha sido produzido qualquer dano, a ser
            arbitrada pelo juízo que for responsável pelo julgamento da causa.
          </Text>
          <Text>
            5.9 O Usuário garante e certifica que, ao acessar ou usar o Serviço,
            não está infringindo quaisquer direitos legais, contratuais ou de
            terceiros, bem como quaisquer outros. Quaisquer reclamações de
            terceiros serão de responsabilidade do Usuário, que deverá assumir
            todas e quaisquer responsabilidades legais e processuais com relação
            à reclamação. O Usuário fica ciente que a SigaPay denunciará todas e
            quaisquer lides, litígios e disputas no qual for envolvida por conta
            de conteúdos e condutas submetidos ou perpetradas por Usuários ou
            terceiros, na medida permitida pela lei, de modo a não mais ser
            parte da lide, litígio ou disputa. A SigaPay também se faculta o
            direito de chamar ao processo qualquer Usuário, em razão de
            quaisquer ações originárias de sua conduta e/ou conteúdo submetido.
            Faculta-se também o direito de responsabilizar diretamente o Usuário
            perpetrador da conduta abusiva, valendo-se para isso de todas os
            recursos legalmente possíveis, incluindo direito de regresso, dentre
            outros. Para tanto, a SigaPay irá valer-se dos dados de registro bem
            como quaisquer outros dados técnicos que permitam identificar o
            Usuário, ficando desde já ciente disso o Usuário ou terceiros
            afetados, que não poderão alegar qualquer violação de privacidade
            nesses casos. A SigaPay reserva-se também o direito de cancelar o
            registro a qualquer tempo de qualquer Usuário, por sua única e
            exclusiva discricionariedade, sem qualquer aviso prévio, sempre que
            for inobservado pelo Usuário o disposto nestes Termos de Uso.
          </Text>
          <Text>
            5.10 Todas as marcas, nomes comerciais e sinais distintivos de
            qualquer espécie presentes nas plataformas de acesso e uso do
            Serviço, incluindo sua própria marca e designação, são pertencentes
            a seus respectivos titulares de direito. Para a utilização de
            quaisquer destas marcas, nomes e sinais, é necessário a obtenção da
            respectiva autorização dos seus titulares.
          </Text>
          <Text>6. COMUNICAÇÃO COM A SIGAPAY</Text>
          <Text>
            6.1 Todas as notificações entre Usuário e SigaPay deverão ser feitas
            única e exclusivamente através do canal de contato com a equipe de
            administração do site, por ******* - ********@SigaPay.com.br
          </Text>
          <Text>
            6.2 Todas as notificações e comunicações por parte da SigaPay ao
            Usuário serão consideradas válidas e eficazes, para todos os
            efeitos, quando se derem através de qualquer das seguintes formas:
          </Text>
          <Text>
            envio de mensagem por correio eletrônico a qualquer endereço
            eletrônico fornecido pelo Usuário; envio de carta ao domicílio do
            Usuário quando este tiver fornecido um endereço; comunicação
            telefônica ao número fornecido pelo Usuário; e/ou através de
            mensagens postadas nas plataformas de acesso ao Serviço.
          </Text>
          <Text>
            6.2.1 Neste sentido, todas as notificações que a SigaPay realizar
            serão consideradas válidas quando efetuadas empregando os dados e
            através de qualquer dos meios anteriormente destacados. Para estes
            efeitos, o Usuário declara que todos os dados fornecidos são válidos
            e corretos, e como tais serão considerados, comprometendo-se a
            comunicar e atualizar junto à plataforma SigaPay todas as mudanças
            relativas a seus dados pessoais.
          </Text>
          <Text>7. VIGÊNCIA</Text>
          <Text>
            7.1 O presente Termo tem vigência por prazo indeterminado, tendo seu
            início a partir da data do Aceite Eletrônico por parte do Usuário.
          </Text>
          <Text>8. ENCERRAMENTO DOS SERVIÇOS</Text>
          <Text>
            8.1 A SigaPay poderá alterar, adicionar, excluir, interromper ou
            suspender a prestação dos Serviços a qualquer momento desde que
            comunique o Usuário mediante aviso eletrônico, respeitado o direito
            de utilização dos créditos existentes na Conta do Usuário à época do
            cancelamento ou a restituição de tais créditos ao Usuário.
          </Text>
          <Text>
            8.2 Será considerado motivo justo para a rescisão imediata e
            unilateral destes Termos de Uso, por parte de SigaPay, se a
            utilização dos Serviços ocorrer em violação às disposições do
            presente instrumento, ou ainda, se o Usuário ou os Signatários
            infringirem o ordenamento jurídico vigente, incluindo, mas não se
            limitando, às condutas previstas na cláusula 5.5 deste Termo, sem
            direito a qualquer restituição de créditos do Usuário.
          </Text>
          <Text>9. CONSIDERAÇÕES GERAIS</Text>
          <Text>
            9.1 Estes Termos de Uso representam a totalidade da avença entre
            Usuários, Signatários e SigaPay, revogando todo e qualquer acordo
            oral ou escrito, anterior ou contemporâneo, firmado entre estes com
            relação ao objeto dos presentes Termos de Uso.
          </Text>
          <Text>
            9.2 Usuários, Signatários e SigaPay aceitam a força probante,
            validade e eficácia de comunicações eletrônicas para todos os fins e
            efeitos destes Termos de Uso.
          </Text>
          <Text>
            9.3 Estes Termos de Uso obrigam Usuários, Signatários e SigaPay e
            seus sucessores. Sem prejuízo da disposição anterior, o Usuário não
            poderá ceder a terceiros quaisquer direitos decorrentes destes
            Termos de Uso, em nenhuma hipótese. A SigaPay poderá, a qualquer
            tempo e a seu exclusivo critério, ceder ou transferir, total ou
            parcialmente, os direitos e obrigações decorrentes do presente
            Contrato.
          </Text>
          <Text>
            9.4 Nenhuma alteração ou renúncia de direitos relativa aos presentes
            Termos de Uso será válida exceto se expressamente negociada e
            assinada entre Usuários e SigaPay. A omissão, por qualquer destes,
            em exigir do outro o cumprimento de qualquer obrigação prevista
            neste instrumento não implica em renúncia ao respectivo direito.
          </Text>
          <Text>
            9.5 A nulidade de quaisquer disposições dos presentes Termos de Uso
            não prejudicará a validade das demais. Caso qualquer disposição
            destes Termos de Uso seja anulada, declarada nula ou inexigível, no
            todo ou em parte, este documento será considerado modificado com a
            exclusão ou modificação, na extensão necessária, da disposição nula,
            anulada ou inexigível, de modo que se preserve os Termos de Uso como
            válidos e, na medida do possível, de forma consistente com a
            intenção original dos Usuários e da SigaPay.
          </Text>
          <Text>
            9.6 As Partes elegem o foro Comarca da cidade de São Paulo, do
            Estado de São Paulo, como o único competente para dirimir as
            questões decorrentes destes Termos de Uso com renúncia expressa a
            qualquer outro, por mais privilegiado que seja.
          </Text>
          <Text>Política de Privacidade e Segurança de Dados</Text>
          <Text>
            A SigaPay coleta dados pessoais com a finalidade de prestar seus
            serviços. Somos comprometidos a preservar a privacidade e segurança
            de nossos usuários, com tal processamento de dados sendo feito em
            estrita conformidade às leis e regulamentos aplicáveis, em
            particular com o Regulamento Geral de Proteção de Dados da União
            Europeia (GDPR) e a Lei Geral de Proteção de Dados (LGPD). É
            altamente recomendado que os usuários leiam com atenção a presente
            Política de Privacidade.
          </Text>
          <Text>1. SOBRE A SIGAPAY E A POLÍTICA DE PRIVACIDADE</Text>
          <Text>
            Esta Política de Privacidade e Segurança de Dados (“Política”)
            define as diretrizes para o tratamento e proteção das informações
            pessoais coletadas durante o acesso e uso do serviço de assinatura
            digital de contratos, prova de autenticidade de conteúdo WEB outros
            documentos eletrônicos oferecido por SIGAPAY GESTÃO DE PAGAMENTOS
            LTDA., sociedade empresária limitada inscrita no CNPJ/MF sob o nº
            ******, com sede na *******, São Paulo – SP, CEP *******e inscrita
            no NIRE sob nº: ********
          </Text>
          <Text>
            Esta Política é complementar aos Termos de Uso que regulam o acesso
            e uso do Serviço pelo correspondente usuário (“Usuário”) quando o
            aplicativo de assinatura digital (“Aplicativo”) e/ou o website
            (“Website”) da SigaPay e/ou outros softwares, serviços e/ou recursos
            correlatos são instalados, acessados e/ou utilizados (“Termos de
            Uso”), bem como, estabelece o tratamento que a SigaPay concede às
            informações dos Usuários e dos terceiros envolvidos na assinatura
            digital de contratos e/ou outros documentos (“Signatários”) que são
            capturados e/ou armazenadas pela SigaPay.
          </Text>
          <Text>2. ALTERAÇÕES DA POLÍTICA DE PRIVACIDADE</Text>
          <Text>
            A SigaPay reserva-se o direito de, a seu exclusivo critério,
            modificar esta Política, incluindo novas regras, condições e/ou
            restrições, devendo comunicar isto ao Usuário através das
            plataformas de uso do Serviço, inclusive o Aplicativo e/ou o
            Website. A continuidade do acesso e/ou uso do Serviço pelo Usuário
            implicará em automática aceitação de eventuais atualizações desta.
          </Text>
          <Text>3. DADOS COLETADOS E SUA FINALIDADE</Text>
          <Text>
            Ao acessar o Aplicativo e/ou o Website, independentemente de
            cadastro, certas informações (dados sobre o navegador utilizado,
            tipo de dispositivo, tempo gasto, endereço de IP, sistema
            operacional, navegador, idioma do navegador, fuso horário, horário
            local e geolocalização) serão armazenados em servidores utilizados
            pela SigaPay. Estas e outras informações pessoais capturadas dos
            Usuários e Signatários são necessárias para sua identificação no
            contexto do acesso e utilização dos Serviços. A fim de prestar seus
            serviços de coleta de provas sobre conteúdo online por meio do
            aplicativo e plugin PACWeb, a SigaPay coleta os seguintes dados:
            nome completo, CPF, endereço de e-mail, geolocalização, fuso
            horário, horário local, endereço de IP, sistema operacional,
            navegador e idioma do navegador. Tais dados são coletados para a
            identificação da autoria do relatório gerado, fornecendo assim maior
            grau de segurança e autenticidade para a prova coletada. Além disso,
            a SigaPay também utiliza dados para fins de marketing e comunicação,
            incluindo a divulgação de produtos, serviços, atividades, promoções,
            campanhas e eventos nos quais a SigaPay tenha parte, bem como
            informações de suporte ao usuário, por e-mail e/ou SMS.
          </Text>
          <Text>4. ARMAZENAMENTO DOS DADOS</Text>
          <Text>
            As informações capturadas ficarão armazenadas e obedecem a padrões
            rígidos de confidencialidade e segurança, tais como a criptrografia.
            Entretanto, é importante que o usuário tenha ciência de que pela
            própria natureza e características técnicas da Internet, essa
            proteção não é infalível e encontra-se sujeita à violação pelas mais
            diversas práticas maliciosas. Tais informações serão utilizadas
            internamente para fins operacionais e estratégicos, envolvendo a
            administração do Website e do Serviço, incluindo, dentre outras
            hipóteses, realização de estatísticas e estudos, análise de tráfego,
            administração, gestão, ampliação e melhoramento das funcionalidades
            do Serviço, para customização. A SigaPay armazenará os relatórios
            gerados pelo aplicativo e plugin PACWeb, a fim de otimizar a
            prestação dos seus serviços de coleta de provas sobre conteúdo
            online. A SigaPay não realizará a guarda nem o arquivamento de
            qualquer contrato e/ou outro documento inserido (uploaded) para fins
            de assinatura por meio do Aplicativo. Por esta razão, o Usuário e/ou
            os Signatários dos contratos e/ou outros documentos deverão tomar
            todas as medidas necessárias para a guarda e arquivamento dos
            contratos e/ou outros documentos assinados através do Aplicativo. Os
            números de cartões de crédito ou débito fornecido pelos Usuários são
            utilizados somente no processamento dos pagamentos dos Serviços
            prestados, não sendo armazenados em banco de dados.
          </Text>
          <Text>5. PRAZO DO ARMAZENAMENTO DOS DADOS</Text>
          <Text>
            No que tange ao serviço de coleta de provas sobre conteúdo online
            por meio do aplicativo e plugin PACWeb, a SigaPay armazenará
            criptográficamente os dados referidos no tópico 3 em servidor
            próprio, de modo seguro e protegido contra perdas, má utilizações e
            acessos não autorizados. Estes dados serão utilizados somente para a
            finalidade específica das quais foram coletados e autorizados. Os
            hasches dos relatórios gerados também são armazenados e vinculados
            às contas de cada usuário. Os demais dados coletados serão
            armazenados enquanto forem necessários para a atividade dos usuários
          </Text>
          <Text>5. RELAÇÃO COM TERCEIROS</Text>
          <Text>
            A SigaPay poderá compartilhar os dados pessoais coletados com seus
            parceiros.
          </Text>
          <Text>
            Nenhum documento e/ou informação pessoal será divulgado e/ou
            compartilhado em nenhuma hipótese, exceto se expressamente
            autorizado pelo Usuário e/ou Signatário ou mediante ordem judicial
            ou por determinação legal. Nenhum documento e/ou informação pessoal
            será vendido e as informações pessoais dos Usuários não serão
            expostas individualmente a quaisquer terceiros, exceto conforme
            estabelecido neste instrumento ou na forma da Lei e mediante ordem
            judicial.
          </Text>
          <Text>6. DIREITOS E RESPONSABILIDADES DO USUÁRIO</Text>
          <Text>
            O Usuário garante e se responsabilizar pela veracidade, exatidão,
            vigência e autenticidade das fotos pessoais e outras informações que
            venha a fornecer para uso do Serviço, comprometendo-se a mantê-los
            atualizados. A SigaPay não tem qualquer responsabilidade no caso de
            inserção de dados falsos ou de sua inexatidão, podendo, a seu
            exclusivo critério, suspender e/ou cancelar o cadastro do Usuário, a
            qualquer momento, caso seja detectada qualquer inexatidão. Os
            usuários da SigaPay, em cumprimento ao Regulamento Geral de Proteção
            de Dados, possuem garantidos os direitos à: - Transparência,
            informação, acesso e notificação, de modo a estarem cientes do
            tratamento dado a seus dados; - Retificação de dados incorretos e
            preenchimento de dados incompletos; - Apagamento, que pode ser
            solicitado pelo usuário, além de efetuado automaticamente nos casos
            previstos em lei, como na eventualidade de terem deixado de ser
            necessários para a finalidade pela qual foram coletados; - Oposição
            quanto à possibilidade de recebimento de comunicações diretas via
            e-mail e/ou SMS; - Retirada de consentimento para a coleta e
            utilização de dados, a qualquer tempo; - Não sujeição a decisões
            automatizadas, de modo a solicitar intervenção humana sempre que
            julgarem necessário; - Portabilidade, solicitando a transferência de
            seus dados a entidades terceiras.
          </Text>
          <Text>7. CADASTRAMENTO E ACEITE DOS TERMOS E CONDIÇÕES</Text>
          <Text>
            O cadastramento como usuário para utilização do Serviço (“Usuário”)
            implica em aceitação plena e irrevogável de todos os termos e
            condições vigentes e publicados pela SigaPay nos canais eletrônicos
            para aquisição do Aplicativo, inclusive aceitação desta Política,
            conforme eventualmente alterados. Uma vez cadastrado, o Usuário
            poderá, a qualquer tempo, por meio de ferramenta oferecida no
            Aplicativo e/ou no Website, revisar e alterar suas informações de
            cadastro. O cadastramento do usuário também autoriza a SigaPay a
            elaborar relatórios sobre os contratos e/ou os outros documentos
            assinados e disponibilizar estes relatórios ao Usuário e/ou aos
            demais signatários.
          </Text>
          <Text>8. PUBLICIDADE</Text>
          <Text>
            A SigaPay poderá enviar comunicados e mensagens publicitárias ao
            Usuário fazendo uso de todas as tecnologias e os meios de
            comunicação disponíveis, seja por e-mail, SMS, MMS, mala-direta e
            outros. Todos os boletins eletrônicos e mensagens publicitárias
            enviadas por e-mail sempre trarão opção de cancelamento do envio
            daquele tipo de mensagem. O cancelamento será realizado no tempo
            mínimo necessário. As mensagens e notificações relacionadas ao
            Serviço não poderão ser canceladas, exceto se houver o cancelamento
            do próprio cadastro do Usuário.
          </Text>
          <Text>
            Caso haja alguma dúvida sobre a Política, entre em contato conosco.
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TermosDeUsoModal;
