import React from "react";
import WithQRCode from "./pages/withQRCode/WithQRCode";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import WithoutQRCode from "./pages/whitoutQRCode/WithoutQRCode";
import NotFound from "./pages/notFound/NotFound";
import RemoveAccount from "./pages/RemoveAccount";
import RequestRemoveAccount from "./pages/RequestRemoveAccount";
import CompanyReceipt from "./pages/CompanyReceipt/CompanyReceipt";

export type ICities = {
  city: string;
  id: number;
  status: string;
};

export type IArea = {
  id: number;
  name: string;
};

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <WithoutQRCode />,
    },
    {
      path: "/id/:qrcodeKey",
      element: <WithQRCode />,
    },
    {
      path: "/company/id/:qrcodeKey",
      element: <CompanyReceipt />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
    {
      path: "/solicitar-remocao-conta",
      element: <RequestRemoveAccount />,
    },
    {
      path: "/remocao-conta/:key",
      element: <RemoveAccount />,
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;


// http://localhost:3000/company/id/tik_T5mcoH4EdQdrKNVR