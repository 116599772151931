import { Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header/Header";
import Wrapper from "../../components/wrapper/Wrapper";

const NotFound = () => {
  return (
    <Wrapper>
      <Header />
      <Text>Não encontramos a página.</Text>
      <Link style={{ textDecoration: "underline" }} to="/">
        Voltar para pagina inicial
      </Link>
    </Wrapper>
  );
};

export default NotFound;
