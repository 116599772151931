import React from "react";
import { Input, Flex, Text } from "@chakra-ui/react";
import { FieldError, FieldValues, UseFormRegister } from "react-hook-form";

interface Props extends React.InputHTMLAttributes<any> {
  title: string;
  name: string;
  value: string;
  isVehicle?: boolean;
  register: UseFormRegister<FieldValues>;
  error?: FieldError | any;
}

const CustomInput = ({
  title,
  register,
  name,
  error,
  value,
}: Props) => {
  return (
    <Flex
      direction="column"
      gap="10px"
      w="100%"
      fontSize="10px"
      fontWeight="500"
    >
      <Text fontSize={"xs"} fontWeight={"bold"} color="#000000">
        {title}
      </Text>
        <Flex position="relative" w="100%">
          <Input
            errorBorderColor="#EB214E"
            isInvalid={error}
            borderRadius="15px"
            borderWidth="2px"
            borderColor="#DDE4F1"
            bg="white"
            h="55px"
            value={value}
            {...register(name)}
            autoComplete="off"
            _placeholderShown={{
              bg: "red",
            }}
          />
          <Text position="absolute" top="30%" right="5%" color="#EB214E">
            {error?.message}
          </Text>
        </Flex>
    </Flex>
  );
};

export default CustomInput;
