import React, { ChangeEventHandler } from "react";
import { Select, Flex, Text } from "@chakra-ui/react";
import { FieldError, FieldValues, UseFormRegister } from "react-hook-form";
import { ICities, IArea } from "../../App";
import { captalizingSentence } from "../helpers/Helpers";

type TProps = {
  title: string;
  name: string;
  vehicles?: string[];
  text?: string[];
  areaList?: IArea[];
  options?: ICities[];
  register: UseFormRegister<FieldValues>;
  error?: FieldError | any;
  disabled?: boolean;
  value?: string
  onChangeValue?: (value: string) => void
}

type IVehicleList = {
  car: string;
  motorcycle: string;
  bus: string;
  truck: string;
};

const vehicleList: IVehicleList = {
  motorcycle: "Moto",
  car: "Carro",
  bus: "Ônibus",
  truck: "Caminhão",
};

const SelectInput = ({
  title,
  options,
  name,
  register,
  text,
  areaList,
  error,
  vehicles,
  disabled,
  value,
  onChangeValue
}: TProps) => {
  const newTime = text
    ?.reduce((acc: string[], curr) => {
      if (curr === "60" || curr === "120") {
        return [curr, ...acc];
      }
      return [...acc];
    }, [])
    .map((time: any) => {
      if (time / 60 === 1) {
        return "1 hora";
      } else {
        return `${time / 60} horas`;
      }
    });

  const handleChangeValue = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChangeValue && onChangeValue(event.target.value)
  }

  return (
    <Flex
      direction="column"
      gap="10px"
      w="100%"
      fontSize="10px"
      fontWeight="500"
    >
      <Text fontSize={"xs"} fontWeight={"bold"} color="#000000">
        {title}
      </Text>
      {options && (
        <Select
          errorBorderColor="#EB214E"
          isInvalid={error}
          borderRadius="15px"
          borderWidth="2px"
          borderColor="#DDE4F1"
          bg="white"
          h="55px"
          placeholder="Selecione"
          {...register(name)}
        >
          {options?.map((option, index) => {
            return (
              <option value={option.city} id={option.id.toString()} key={index}>
                {captalizingSentence(option.city)}
              </option>
            );
          })}
        </Select>
      )}
      {areaList && (
        <Select
          errorBorderColor="#EB214E"
          isInvalid={error}
          borderRadius="15px"
          borderWidth="2px"
          borderColor="#DDE4F1"
          bg="white"
          h="55px"
          placeholder="Selecione"
          {...register(name)}
          disabled={disabled}
        >
          {areaList?.map((area, index) => {
            return (
              <option value={area.name} key={index}>
                {captalizingSentence(area?.name)}
              </option>
            );
          })}
        </Select>
      )}
      {text && (
        <Select
          errorBorderColor="#EB214E"
          isInvalid={error}
          borderRadius="15px"
          borderWidth="2px"
          borderColor="#DDE4F1"
          bg="white"
          h="55px"
          placeholder="Selecione"
          {...register(name)}
          disabled={disabled}
          value={value}
          onChange={handleChangeValue}
        >
          {newTime?.map((option, index) => {
            return (
              <option value={option === "2 horas" ? 120 : 60} key={index}>
                {option}
              </option>
            );
          })}
        </Select>
      )}
      {vehicles && (
        <Select
          errorBorderColor="#EB214E"
          isInvalid={error}
          borderRadius="15px"
          borderWidth="2px"
          borderColor="#DDE4F1"
          bg="white"
          h="55px"
          placeholder="Selecione"
          {...register(name)}
          disabled={disabled}
          value={value}
          onChange={handleChangeValue}
        >
          {vehicles?.map((vehicle, index) => {
            return (
              <option value={vehicle} key={index}>
                {vehicleList[vehicle as keyof IVehicleList]}
              </option>
            );
          })}
        </Select>
      )}

      <Flex color="#EB214E">{!!error && error.message}</Flex>
    </Flex>
  );
};

export default SelectInput;
