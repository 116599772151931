import React from "react";
import { Flex } from "@chakra-ui/react";
const PixCard = ({ children }: any) => {
  return (
    <Flex
      w="100%"
      h="fit-content"
      p="30px"
      justifyContent="center"
      align="center"
      bg="white"
      boxShadow="0px 0px 50px rgba(0, 52, 127, 0.1)"
      borderRadius="15px"
    >
      {children}
    </Flex>
  );
};

export default PixCard;
