import React, { useState, useEffect, useCallback } from "react";
import Wrapper from "../../components/wrapper/Wrapper";
import Header from "../../components/header/Header";
import MainText from "../../components/header/MainText";
import MainButton from "../../components/Button/MainButton";
import SimpleInput from "../../components/inputs/SimpleInput";
import SelectInput from "../../components/inputs/SelectInput";
import Card from "../../components/card/Card";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FieldValues } from "react-hook-form";
import { Alert, AlertIcon, Checkbox, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { ReactComponent as RightArrow } from "../../components/assets/icons/rightArrow.svg";
import { API_URL } from "../../components/helpers/Helpers";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import TermosDeUsoModal from "../../components/modal/TermosDeUsoModal";
import GenericHeader from "../../components/header/GenericHeader";
import Input from "../../components/inputs/CustomInput";
import CustomInput from "../../components/inputs/CustomInput";
import { AlertStatus } from "../RequestRemoveAccount";

const RemoveAccount = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: { code: "" },
    resolver: yupResolver(
      yup.object().shape({
        code: yup.string().required("Código obrigatório").length(5, "Código inválido"),
      })
    ),
  });
  const { key }: any = useParams();
  const [alertStatus, setAlertStatus] = useState<AlertStatus>("error");
  const [messageAlert, setMessageAlert] = useState("");
  const [showInput, setShowInput] = useState(true)

  function submit(data: FieldValues) {
    fetch(`${API_URL}/auth/account/exclude-account`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: data.code,
        key
      }),
    }).then((res) => res.json())
      .then((data) => {
        if (!data.ok) {
          if (data.error.message === "Token inválido") {
            setAlertStatus("error")
            setMessageAlert("Código inválido")
          } else {
            setMessageAlert(data.error.message)
          }
          setTimeout(() => {
            setMessageAlert("")
          }, 2000);
        }

        if (data.ok) {
          setAlertStatus("success")
          setMessageAlert("Conta excluída com sucesso")
          setShowInput(false)
        }
      })
  }



  return (
    <Wrapper opacity={"1"}>
      {messageAlert && <Alert status={alertStatus}>
        <AlertIcon />
        {messageAlert}
      </Alert>}
      {showInput && <div>
        <GenericHeader />
        <form
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
          }}
        >
          <CustomInput
            title="Informe o código de verificação enviado para seu e-mail"
            register={register}
            value={watch("code")}
            name="code"
            error={errors.code}
          />

          <MainButton
            type="submit"
            text="Excluir"
            justifyButton={"center"}
            onClick={handleSubmit(submit)}
          />
        </form>
      </div>}
    </Wrapper>
  );
};

export default RemoveAccount;
