import React from "react";
import { Flex, Icon } from "@chakra-ui/react";
import { ReactComponent as SigaPay } from "../assets/icons/sigapayIcon.svg";
import { ReactComponent as PixIcon } from "../assets/icons/pixIcon.svg";
const GenericHeader = () => {
  return (
    <Flex w="100%" justify="center" align="center">
      <Icon w="96px" h="50px" as={SigaPay} />
    </Flex>
  );
};

export default GenericHeader;
