import React, { useState, useEffect, useCallback } from "react";
import Wrapper from "../../components/wrapper/Wrapper";
import Header from "../../components/header/Header";
import MainText from "../../components/header/MainText";
import MainButton from "../../components/Button/MainButton";
import SimpleInput from "../../components/inputs/SimpleInput";
import SelectInput from "../../components/inputs/SelectInput";
import Card from "../../components/card/Card";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FieldValues } from "react-hook-form";
import { Checkbox, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { ReactComponent as RightArrow } from "../../components/assets/icons/rightArrow.svg";
import { API_URL } from "../../components/helpers/Helpers";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import TermosDeUsoModal from "../../components/modal/TermosDeUsoModal";

export type ICities = {
  city: string;
  id: number;
  status: string;
};

export type IArea = {
  id: number;
  name: string;
};

type IFares = {
  id: number;
  minutes: number;
  price: number;
  vehicle_type: string;
  area_id: number;
};

const WithoutQRCode = () => {
  const [cities, setCities] = useState<ICities[]>([]);
  const [areaList, setAreaList] = useState<IArea[]>([]);
  const [fares, setFares] = useState<IFares[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [captcha, setCaptcha] = useState<string>("");
  const [isChecked, setIsChecked] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [vehicleType, setVehicleType] = useState('');
  const [duration, setDuration] = useState('');
  const [price, setPrice] = useState(0);


  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FieldValues>({
    resolver: yupResolver(
      yup.object().shape({
        celular: yup
          .string()
          .min(11, "Digite um número válido")
          .transform((value) => value.replace(/[^\d]/g, ""))
          .required(),
        user_name: yup.string().required("Nome e sobrenome são obrigatórios!"),
        city: yup.string().required("Selecione uma cidade"),
        // area: yup.string().when({
        //   is: () => areaList.length > 0,
        //   then: yup.string().required("Selecione uma area"),
        // }),
        licencePlate: yup
          .string()
          .trim()
          .matches(/[A-Z]{3}[0-9][0-9A-Z][0-9]{2}/, "Placa não reconhecida")
          .required(),
        vehicle_type: yup
          .string()
          .trim("Selecione um veículo")
          .required("Selecione um veículo"),
        duration: yup.string().required("Selecione um tempo de duração"),
      })
    ),
  });

  const navigate = useNavigate();

  const cityValue = watch("city");
  const areaValue = watch("area");

  useEffect(() => {
    fetch(`${API_URL}/api/operations`)
      .then((res) => res.json())
      .then((data) => {
        setCities(data.data);
      });
  }, []);

  useEffect(() => {
    const id = cities.find((city) => city.city === cityValue)?.id;
    if (id) {
      fetch(`${API_URL}/api/operations/id/${id}`)
        .then((res) => res.json())
        .then(({ data }) => {
          console.log("data.areas =>", data.areas);
          setAreaList(data.areas);
          setFares(data.fares.filter((fare: any) => fare.vehicle_type));
        });
    }
  }, [cityValue, cities]);

  useEffect(() => {
    setValue("area", "");
    setVehicleType("");
    setDuration("");
    setPrice(0)
  }, [cityValue, setValue]);

  useEffect(() => {
    setDuration("");
    setPrice(0)
  }, [areaValue, setValue]);

  const areaID = areaList.filter((area) => area.name === watch("area"))[0]?.id;
  const cityID = cities.find((city) => city.city === cityValue)?.id;

  useEffect(() => {
    const price = fares.filter((fare) => {
      if (areaID) {
        return (
          fare.vehicle_type === vehicleType &&
          fare.minutes === +duration &&
          fare.area_id === areaID
        );
      } else {
        return (
          fare.vehicle_type === vehicleType &&
          fare.minutes === +duration
        );
      }
    })[0]?.price;

    setPrice(price)
  }, [duration, vehicleType])

  const filteringVehicles = [
    ...new Set(fares.map((fare) => fare.vehicle_type)),
  ];

  const filteringTime = [
    ...new Set(
      fares
        .filter((fare) => {
          if (areaID) {
            return (
              fare.vehicle_type === vehicleType &&
              fare.area_id === areaID
            );
          } else {
            return fare.vehicle_type === vehicleType;
          }
        })
        .map((fare) => fare.minutes.toString())
    ),
  ];

  console.log(price);

  async function submit(payload: any) {
    const { licencePlate, vehicle_type, duration } = payload;
    const newPayload = {
      phone: watch("celular").replace(/[^0-9]/g, ""),
      user_name: watch("user_name"),
      vehicle_type,
      duration,
      plate: licencePlate.toString().toUpperCase(),
      operation_id: cityID,
      price: price,
      area_id: areaList.length > 0 ? areaList[0].id : null,
      recaptcha: captcha,
    };
    setIsLoading(true);
    try {
      fetch(`${API_URL}/api/tickets/payments/pix`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newPayload),
      })
        .then((res) => res.json())
        .then(({ data }) => {
          setIsLoading(false);
          navigate(`id/${data.id}`);
        })
        .catch((error) => console.log(error));
    } catch (error: any) {
      console.log(error);
    }
  }

  const recaptchaHandler = useCallback((token: string) => {
    setCaptcha(token);
  }, []);

  return (
    <Wrapper opacity={isLoading ? "0.8" : "1"}>
      <Header />
      <MainText text="Olá, preencha os dados abaixo e siga tranquilo." />
      <form
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
        onSubmit={handleSubmit(submit)}
      >
        <SimpleInput
          title="PREENCHA O TELEFONE PARA RECEBER OS COMPROVANTES"
          register={register}
          value={watch("celular")}
          name="celular"
          error={errors.celular}
        />
        <SimpleInput
          title="PREENCHA SEU NOME E SOBRENOME"
          register={register}
          name="user_name"
          isVehicle={true}
          value={watch("user_name")}
          error={errors.user_name}
        />
        <SelectInput
          title="EM QUAL CIDADE VOCÊ ESTÁ"
          options={cities}
          register={register}
          name="city"
          error={errors.city}
        />
        {/* <SelectInput
          title="QUAL A AREA DO ESTACIONAMENTO"
          areaList={areaList}
          register={register}
          name="area"
          error={errors.area}
          disabled={areaList.length === 0}
        /> */}
        <SimpleInput
          title="PLACA DO VEÍCULO"
          register={register}
          name="licencePlate"
          isVehicle={true}
          value={watch("licencePlate")}
          error={errors.licencePlate}
        />
        <SelectInput
          title="QUAL TIPO DE VEÍCULO"
          vehicles={filteringVehicles}
          register={register}
          name="vehicle_type"
          error={errors.vehicle_type}
          disabled={!watch("city")}
          value={vehicleType}
          onChangeValue={value => setVehicleType(value)}
        />
        <SelectInput
          title="QUANTO TEMPO VOCÊ VAI FICAR?"
          text={filteringTime}
          register={register}
          name="duration"
          error={errors.duration}
          disabled={!vehicleType}
          value={duration}
          onChangeValue={value => setDuration(value)}
        />
        <Card
          city={watch("city")}
          area={watch("area")}
          vehicle={vehicleType}
          time={Number(duration)}
          price={price}
          licencePlate={watch("licencePlate")}
        />
        <GoogleReCaptcha onVerify={recaptchaHandler} />
        <Flex gap="10px" px="5px">
          <Checkbox
            size="lg"
            borderColor="blue.600"
            colorScheme="blue"
            isChecked={isChecked}
            onChange={() => setIsChecked((prev) => !prev)}
          />
          <Text>Aceito e concordo com os</Text>
          <Text textDecoration="underline" color="#015CFF" onClick={onOpen}>
            Termos de uso
          </Text>
        </Flex>
        <MainButton
          disabled={captcha === "" || isLoading || !isChecked}
          type="submit"
          text="Gerar PIX de pagamento"
          icon={RightArrow}
          isLoading={isLoading}
        />
      </form>
      {/* <Flex gap="5px" fontWeight="400" fontSize="15px">
        <Text color="#444444">Precisa de ajuda?</Text>
        <Text color="#015CFF" textAlign="right" textDecoration="underline">
          Clique aqui
        </Text>
      </Flex> */}
      <TermosDeUsoModal isOpen={isOpen} onClose={onClose} />
    </Wrapper>
  );
};

export default WithoutQRCode;
