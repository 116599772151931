import React, { useState, useEffect, useCallback } from "react";
import Wrapper from "../../components/wrapper/Wrapper";
import Header from "../../components/header/Header";
import MainText from "../../components/header/MainText";
import MainButton from "../../components/Button/MainButton";
import SimpleInput from "../../components/inputs/SimpleInput";
import SelectInput from "../../components/inputs/SelectInput";
import Card from "../../components/card/Card";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FieldValues } from "react-hook-form";
import { Alert, AlertIcon, Checkbox, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { ReactComponent as RightArrow } from "../../components/assets/icons/rightArrow.svg";
import { API_URL } from "../../components/helpers/Helpers";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import TermosDeUsoModal from "../../components/modal/TermosDeUsoModal";
import GenericHeader from "../../components/header/GenericHeader";
import Input from "../../components/inputs/CustomInput";
import CustomInput from "../../components/inputs/CustomInput";

export type AlertStatus = "error" | "info" | "warning" | "success" | "loading" | undefined

const RequestRemoveAccount = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: { email: "" },
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().email('Email inválido').required('Email obrigatório'),
      })
    ),
  });

  const navigate = useNavigate()
  const [alertStatus, setAlertStatus] = useState<AlertStatus>("error");
  const [messageAlert, setMessageAlert] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  function submit(data: FieldValues) {
    setIsLoading(true)
    fetch(`${API_URL}/auth/account/request-exclusion`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => res.json())
      .then((data) => {
        if (!data.ok) {
          setMessageAlert(data.error.message)
          setTimeout(() => {
            setAlertStatus("error")
            setMessageAlert("")
          }, 2000);
        } else {
          navigate(`/remocao-conta/${data.data.key}`)
        }
      })
      .catch((error) => {
        setMessageAlert(data.error.message)
        setTimeout(() => {
          setAlertStatus("error")
          setMessageAlert("")
        }, 2000);
      })
      .finally(() => setIsLoading(false))
  }


  return (
    <Wrapper opacity={"1"}>
      {messageAlert && <Alert status={alertStatus}>
        <AlertIcon />
        {messageAlert}
      </Alert>}
      <GenericHeader />
      <MainText text="Para excluir sua conta, digite seu e-mail e clique em “enviar” para receber o código de confirmação de exclusão do seu cadastro Sigapay" />
      <form
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <CustomInput
          title="PREENCHA SEU E-MAIL"
          register={register}
          value={watch("email")}
          name="email"
          error={errors.email}
        />

        <MainButton
          type="submit"
          text="Enviar"
          justifyButton={"center"}
          onClick={handleSubmit(submit)}
        />
      </form>
    </Wrapper>
  );
};

export default RequestRemoveAccount;
