import React from "react";
import { Button, Flex, Text, Icon, Spinner, FlexProps } from "@chakra-ui/react";

type Props = {
  text: string;
  icon?: React.FC;
  type: any;
  onClick?: any;
  disabled?: boolean;
  isLoading?: boolean;
  justifyButton?: string;
};

const MainButton = ({
  text,
  icon,
  type,
  onClick,
  disabled,
  isLoading,
  justifyButton = "space-between",
  ...rest
}: Props & FlexProps) => {
  return (
    <Button
      bgGradient="linear(175.08deg,#5DBBFF 4.48%, #015CFF 96.57%, #015CFF 96.57%)"
      w="100%"
      color="white"
      borderRadius="15px"
      p="20px"
      h="60px"
      colorScheme="none"
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      <Flex
        w="100%"
        justify={isLoading ? "center" : "space-between"}
        align="center"
        {...rest}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <Flex
            w="100%"
            justify={isLoading ? "center" : justifyButton}
            align="center"
          >
            <Text>{text}</Text>
            {icon && <Icon as={icon} />}
          </Flex>
        )}
      </Flex>
    </Button>
  );
};

export default MainButton;
