import React from "react";
import { Flex, Icon } from "@chakra-ui/react";
import { ReactComponent as SigaPay } from "../assets/icons/sigapayIcon.svg";
import { ReactComponent as PixIcon } from "../assets/icons/pixIcon.svg";
type Props = {
isPIX?: boolean;
}
const Header = ({isPIX=true}:Props) => {
  return (
    <Flex w="100%" justify="space-between" align="center">
      <Icon w="96px" h="50px" as={SigaPay} />
      {isPIX && <Icon w="96px" h="50px" as={PixIcon} />}
    </Flex>
  );
};

export default Header;
