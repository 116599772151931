import React from "react";
import { Input, Flex, Text } from "@chakra-ui/react";
import { FieldError, FieldValues, UseFormRegister } from "react-hook-form";
import ReactInputMask from "react-input-mask";

interface Props extends React.InputHTMLAttributes<any> {
  title: string;
  name: string;
  value: string;
  isVehicle?: boolean;
  register: UseFormRegister<FieldValues>;
  error?: FieldError | any;
}

const SimpleInput = ({
  title,
  register,
  name,
  error,
  value,
  isVehicle,
}: Props) => {
  return (
    <Flex
      direction="column"
      gap="10px"
      w="100%"
      fontSize="10px"
      fontWeight="500"
    >
      <Text fontSize={"xs"} fontWeight={"bold"} color="#000000">
        {title}
      </Text>

      {isVehicle ? (
        <Flex position="relative" w="100%">
          <Input
            borderRadius="15px"
            errorBorderColor="#EB214E"
            isInvalid={error}
            borderWidth="2px"
            borderColor="#DDE4F1"
            bg="white"
            w="100%"
            h="55px"
            value={isVehicle ? value?.toUpperCase() : value}
            {...register(name)}
            autoComplete="off"
          />
          <Text position="absolute" top="30%" right="5%" color="#EB214E">
            {error?.message}
          </Text>
        </Flex>
      ) : (
        <Flex position="relative" w="100%">
          <Input
            as={ ReactInputMask}
            mask={!isVehicle && "(99) 99999-9999"}
            errorBorderColor="#EB214E"
            isInvalid={error}
            borderRadius="15px"
            borderWidth="2px"
            borderColor="#DDE4F1"
            bg="white"
            h="55px"
            value={value}
            {...register(name)}
            autoComplete="off"
            _placeholderShown={{
              bg: "red",
            }}
          />
          <Text position="absolute" top="30%" right="5%" color="#EB214E">
            {error?.message}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default SimpleInput;
