import { Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import MainButton from "../../components/Button/MainButton";
import { ReactComponent as ButtonIcon } from "../../components/assets/icons/Union.svg";
import Card from "../../components/card/Card";
import PixCard from "../../components/card/PixCard";
import TextCounter from "../../components/counter/TextCounter";
import Header from "../../components/header/Header";
import MainText from "../../components/header/MainText";
import { API_URL } from "../../components/helpers/Helpers";
import Wrapper from "../../components/wrapper/Wrapper";
import Paid from "../paid/Paid";
import WithQRCode from "../CompanyReceipt/CompanyReceipt";

type ILocalData = {
  qrcode: string;
  city: string;
  area: string;
  vehicle_type: string;
  licencePlate: string;
  duration: number;
  price: number;
  createdAt: string;
};

const CompanyReceipt = () => {
  const { qrcodeKey }: any = useParams();
  const toast = useToast();
  const [localData, setLocalData] = useState<ILocalData>({
    qrcode: "",
    city: "",
    area: "",
    vehicle_type: "",
    licencePlate: "",
    duration: 0,
    price: 0,
    createdAt: "",
  });
  const [ticketNumber, setTicketNumber] = useState();
  const [startAt, setStartAt] = useState("");
  const [endAt, setEndAt] = useState("");
  const [isPaid, setIsPaid] = useState(false);
  const [isLoading, setIsLoading] = useState(true)

  const copy = () => {
    if (localData?.qrcode) {
      // navigator.clipboard.writeText(localData?.qrcode);
      if (navigator.userAgent.indexOf("Safari") === -1) {
        try {
          navigator.clipboard.write([
            new ClipboardItem({
              "text/text": Promise.resolve(localData.qrcode).then((result) => {
                if (!result) {
                  return new Promise(async (resolve) => {
                    resolve(new Blob([``]));
                  });
                }
                return new Promise(async (resolve) => {
                  resolve(new Blob([localData.qrcode]));
                });
              }),
            }),
          ]);
        } catch (error) {
          console.log(error);
        }
        toast({
          position: "bottom",
          render: () => (
            <Flex
              justify="center"
              align="center"
              bg="#4bb543"
              direction="column"
              fontSize="15px"
              p="5px"
              pb="8px"
              borderRadius="15px"
              color="white"
            >
              <Text>Código copiado!</Text>
              <Text mx="5px">
                Abra o App do seu banco e efetue o pagamento.
              </Text>
            </Flex>
          ),
        });
      } else {
        toast({
          position: "bottom",
          render: () => (
            <Flex
              justify="center"
              align="center"
              bg="#4bb543"
              direction="column"
              fontSize="15px"
              p="5px"
              pb="8px"
              borderRadius="15px"
              color="white"
            >
              <Text>Código copiado!</Text>
              <Text mx="5px">
                Abra o App do seu banco e efetue o pagamento.
              </Text>
            </Flex>
          ),
        });
        try {
          navigator.clipboard.writeText(localData?.qrcode);
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  useEffect(() => {
    fetch(`${API_URL}/api/tickets/invoices/id/${qrcodeKey}`)
      .then((res) => res.json())
      .then(({ data }) => {
        if (data.paid_at) {
          changeLayoutToPaid(data)
        }
        addLocalData(data) 
      }).finally(() => setIsLoading(false));
  }, [qrcodeKey]);

  useEffect(() => {
    if (qrcodeKey) {
      const timer = setInterval(() => {
        fetch(`${API_URL}/api/tickets/invoices/id/${qrcodeKey}`)
          .then((res) => res.json())
          .then(({ data }) => {
            if (data.paid_at) {
              changeLayoutToPaid(data)
              clearInterval(timer);
            }
          });
      }, 5000);
      return () => clearInterval(timer);
    }
  }, [qrcodeKey]);

  const changeLayoutToPaid = (data: any) => {
    setTicketNumber(data.ticket.identifier);
    setIsPaid(true);
    setStartAt(data.ticket.start_at);
    setEndAt(data.ticket.end_at);
  }

  const addLocalData = (data: any) => {
    setLocalData({
      qrcode: data.transaction.Key,
      city: data.operation.city,
      area: data.area?.name,
      vehicle_type: data.vehicle_type,
      licencePlate: data.plate,
      duration: data.duration,
      price: data.price,
      createdAt: data.created_at,
    });
  }

  // useEffect(() => {
  //   if (qrcodeKey && localData.qrcode !== "") {
  //     setTimeout(() => {
  //       fetch(
  //         `${API_URL}/api/tickets/invoices/id/${qrcodeKey}/transaction/status/3`,
  //         {
  //           method: "PATCH",
  //           headers: {
  //             Accept: "application/json",
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       ).then((res) => res.json());
  //     }, 2000);
  //   }
  // }, [qrcodeKey, localData.qrcode]);

  const getHoursAndMins = (date: string) => {
    return [new Date(date).getHours(), new Date(date).getMinutes()];
  };

  return (
    <>
      {isLoading ? (
        <Wrapper>
          <Spinner size={'lg'} />
        </Wrapper>
      ) : isPaid ? (
        <Paid
          city={localData?.city}
          area={localData?.area}
          vehicle_type={localData?.vehicle_type}
          duration={localData?.duration}
          price={localData?.price}
          licencePlate={localData?.licencePlate}
          ticketNumber={ticketNumber}
          startAt={getHoursAndMins(startAt)}
          endAt={getHoursAndMins(endAt)}
        />
      ) : (
        <Wrapper>
          <Header />
          <MainText text="Agora é só você efetuar o pagamento!" />
          <PixCard>
            <QRCode size={256} value={localData?.qrcode} />
          </PixCard>
          <Flex w="100%" overflowX="hidden">
            {" "}
          </Flex>
          <MainButton
            type=""
            text="Copiar código do QR Code"
            icon={ButtonIcon}
            onClick={copy}
          />
          <TextCounter createdAt={localData.createdAt} />
          <Card
            city={localData?.city}
            area={localData?.area}
            vehicle={localData?.vehicle_type}
            time={localData?.duration}
            price={localData?.price}
            licencePlate={localData?.licencePlate}
          />
          {/* <Flex gap="5px" fontWeight="400" fontSize="15px">
            <Text color="#444444">Precisa de ajuda?</Text>
            <Text color="#015CFF" textAlign="right" textDecoration="underline">
              Clique aqui
            </Text>
          </Flex> */}
        </Wrapper>
      )}
    </>
  );
};

export default CompanyReceipt;
