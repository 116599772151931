export function formatTime(time: number): string {
  const hour = Math.floor(time / 60);
  const minute = time % 60;

  let formatTime = "";
  if (hour > 0) {
    formatTime += `${hour} hora${hour > 1 ? "s" : ""}`;
    if (minute > 0) {
      formatTime += " e ";
    }
  }
  if (minute > 0) {
    formatTime += `${minute} minuto${minute > 1 ? "s" : ""}`;
  }

  return formatTime;
}