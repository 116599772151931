export const captalizingSentence = (str: string) => {
  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  return str.toLowerCase().split(" ").map(capitalize).join(" ");
};

export const ajustingTime = (time: number) => {
  return time / 60 < 1
    ? "30 minutos"
    : time / 60 === 1
    ? "1 hora"
    : time / 60 + " horas";
};

export const API_URL = process.env.REACT_APP_API_URL;
