import React from "react";
import Wrapper from "../../components/wrapper/Wrapper";
import Header from "../../components/header/Header";
import Card from "../../components/card/Card";
import { Flex, Text, Icon } from "@chakra-ui/react";
import { ReactComponent as SuccessIcon } from "../../components/assets/icons/Subtract.svg";

type Props = {
  city: string;
  area: string;
  vehicle_type: string;
  duration: number;
  price: number;
  licencePlate: string;
  ticketNumber: any;
  startAt: number[];
  endAt: number[];
  isPIX?: boolean;
};

const Paid = ({
  city,
  area,
  vehicle_type,
  duration,
  price,
  licencePlate,
  ticketNumber,
  startAt,
  endAt,
  isPIX = true
}: Props) => {
  return (
    <Wrapper>
      <Header isPIX={isPIX} />
      <Flex w="100%" align="center" gap="10px">
        <Icon h="40px" w="40px" as={SuccessIcon} />
        <Flex direction="column">
          <Text fontSize="20px" fontWeight="700">
            Recebemos o pagamento!
          </Text>
          <Text fontSize="15px" fontWeight="400">
            Agora é só seguir tranquilo.
          </Text>
        </Flex>
      </Flex>
      <Flex direction="column" w="100%" bg="white" borderRadius="15px">
        <Flex
          borderRadius="13px 13px 0px 0px"
          bg="#EEF2F8"
          h="74px"
          // w="100%"
          justify="center"
          align="center"
          direction="column"
          color="#444444"
          mx="3px"
          mt="3px"
        >
          <Text fontSize="13px" fontWeight="400">
            Nº do ticket
          </Text>
          <Text fontSize="20px" fontWeight="600">
            {ticketNumber}
          </Text>
        </Flex>
        <Card
          city={city}
          area={area}
          vehicle={vehicle_type}
          time={duration}
          price={price}
          licencePlate={licencePlate}
          startAt={startAt}
          endAt={endAt}
        />
      </Flex>
      <Flex
        w="100%"
        fontSize="15px"
        fontWeight="400"
        justify="space-between"
        align="center"
      >
        <Flex w="fit-content" direction="column" gap="5px">
          <Text color="#444444">Precisa de ajuda?</Text>
          <Text color="#015CFF" textDecoration="underline">
            Clique aqui
          </Text>
        </Flex>
        <Flex w="fit-content" direction="column" gap="5px">
          <Text color="#444444">Quer estacionar?</Text>
          <Text color="#015CFF" textAlign="right" textDecoration="underline">
            Clique aqui
          </Text>
        </Flex>
      </Flex>
    </Wrapper>
  );
};

export default Paid;
