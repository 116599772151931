import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { captalizingSentence } from "../helpers/Helpers";
import { formatTime } from "../../utils/format-time";

type Props = {
  city: string;
  area: string;
  vehicle: string;
  time: number;
  price: number;
  licencePlate: string;
  startAt?: number[];
  endAt?: number[];
};

type IVehicleList = {
  car: string;
  motorcycle: string;
  bus: string;
  truck: string;
};

const vehicleList: IVehicleList = {
  car: "Carro",
  motorcycle: "Moto",
  bus: "Ônibus",
  truck: "Caminhão",
};

const Card = ({
  city,
  area,
  vehicle,
  time,
  price,
  licencePlate,
  startAt,
  endAt,
}: Props) => {
  // const range = (start: number[], end: number[]) => {
  //   const newStart = start
  //     .map((number) => (number < 10 ? "0" + number : number))
  //     .join(":");
  //   const newEnd = end
  //     .map((number) => (number < 10 ? "0" + +number : number))
  //     .join(":");
  //   return `- ${newStart} às ${newEnd}`;
  // };
  console.log("Card Time ->",time)
  console.log("Card Time Type ->",typeof time)
  return (
    <Flex
      w="100%"
      h="100%"
      direction="column"
      bg="white"
      borderRadius="15px"
      boxShadow="0px 0px 50px rgba(0, 52, 127, 0.1)"
    >
      <Flex
        w="100%"
        justify="space-between"
        borderBottomWidth="3px"
        borderColor="rgba(221, 228, 241, 0.5)"
        p="20px"
        fontSize="20px"
        fontWeight="500"
      >
        <Text>Total</Text>
        <Text>R$ {price ? (price / 100).toFixed(2) : "0,00"}</Text>
      </Flex>
      <Flex
        w="100%"
        h="100%"
        direction="column"
        fontSize="13px"
        gap="20px"
        p="20px"
      >
        <Text fontSize="15px">Resumo</Text>
        <Flex w="100%" justify="space-between">
          <Text>Cidade</Text>
          <Text>{city ? captalizingSentence(city) : "---"}</Text>
        </Flex>
        <Flex w="100%" justify="space-between">
          <Text>Area</Text>
          <Text>{area ? captalizingSentence(area) : "---"}</Text>
        </Flex>
        <Flex w="100%" justify="space-between">
          <Text>Veiculo</Text>
          <Text>
            {vehicle
              ? vehicleList[vehicle as keyof IVehicleList] +
                " - " +
                licencePlate
              : "---"}
          </Text>
        </Flex>
        <Flex w="100%" justify="space-between">
          <Text>Tempo</Text>
          <Text>
            {/* {time ? time + " minutos" : "---"}{" "}
            {startAt && endAt && range(startAt, endAt)} */}
            {formatTime(time)}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Card;
